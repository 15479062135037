'use strict';

import * as _ from 'underscore';
import observable from 'riot-observable';
import route from 'riot-route';

/**
 * @param {string} initial - The initial route to use
 */
export default function Router(defaultRoute=["landing"]) {
  this.defaultRoute = defaultRoute;
  this.current = defaultRoute;

  observable(this);
  route(_.bind(listener, this));
}

Router.prototype = {
  defaultRoute: ['unknown'],
  current: [],

  /**
   * This should be called after tags have been mounted,
   * in order to ensure that route listeners are all registered.
   */
  start: function() {
    route.start();
    // Fire the current route
    route.exec(_.bind(listener, this));
  },

  /**
   * Determine if the current route is equal or a subset of the supplied route.
   * @param {(string|string[])} route - Either the base route or a list of paths.
   * @returns {boolean}
   */
  is: function(route) {
    if (_.isString(route)) { return this.current[0] === route; }
    if (_.isArray(route)) {
      if (route.length > this.current.length) { return false }
      return _.chain(route).zip(this.current).reduce((m, [l, r]) => m && l === r, true).value
    }

    return false
  },

  /**
   * Go to the new given route.
   * @param {(string|string[])} route
   */
  go: function(routePath) {
    // Type check. Because `route` can actually take a callback but
    // this method should not support that functionality.
    if (!_.isString(routePath) && !_.isArray(routePath)) {
      throw new Error("Router.go() only accepts a string or array.")
    }

    if (_.isArray(routePath)) { route(routePath.join('/')); }
    else if (route === '') { route('/'); }
    else { route(routePath); }
  }

};

function listener(...parts) {
  if (parts.length === 0 || _.every(parts, x => x === "")) {
    this.current = this.defaultRoute;
  } else {
    this.current = parts;
  }
  this.trigger && this.trigger('update', this.current);
}
