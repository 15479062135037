<landing class="{style.landing} {opts.class}">

  <div class="section-intro">
    <container class="container">
      <div class="introduction">
        <div class="introduction-container">
          <h1 class="introduction-title">
            Keep your private bits<br />
            <em>off the internet</em>
          </h1>

          <h2 class="introduction-subtitle">
            Share photos and videos securely. Be in control over when or how they can be accessed.
          </h2>

          <div class="app-store-button">
            <app-store-button />
          </div>
        </div>

        <div class="section-intro-image"></div>
      </div>
    </container>
  </div>

  <div class="section-quote">
    <container class="container">
      <blockquote class="quote">
        <p>If you want to record cool stuff but don’t want to risk it
          appearing on the internet, this is the app.</p>
      </blockquote>

      <cite class="citation">– App Store Reviewer</cite>
    </container>
  </div>


  <div class="section-security">
    <container class="container">
      <div class="dual-key">
        <div class="image keys-image"></div>

        <h2>Dual-key access</h2>

        <p>Photos or videos shared through the app are encrypted with two keys stored on separate devices. Mutual consent is required during playback.</p>
      </div>

      <div class="divider-container">
        <div class="divider"></div>
      </div>

      <div class="end-to-end-encryption">
        <div class="image safe-deposit-box-image"></div>

        <h2>End-to-end encryption</h2>

        <p>No one else can view your photos or videos since they are transmitted with end-to-end encryption, this includes
        thieves, snoops or even us.</p>
      </div>
    </container>
  </div>

  <div class="section-access-controls" ref="sectionAccessControls">
    <div class="scroll-wrapper" ref="sectionAccessControlsScrollWrapper">

      <container class="container">
        <div class="heading">
          <h1>Unique access controls</h1>
        </div>
      </container>

      <container class="container">
        <div class="content">
          <ul class="access-controls-list">
            <li class={
                      shown: parent.accessControlExpanded === 1,
                      visible: parent.accessControlExpanded === -1 || parent.accessControlExpanded === 1,
                      }>
              Switch access on or off at your discretion
              <div class="line"></div>
              <div class="dot"></div>
            </li>

            <li class={
                      shown: parent.accessControlExpanded === 2,
                      visible: parent.accessControlExpanded === -2 || parent.accessControlExpanded === 2,
                      }>
              Alternatively, share access one-at-a-time
              <div class="line"></div>
              <div class="dot"></div>
            </li>

            <li class={
                      shown: parent.accessControlExpanded === 3,
                      visible: parent.accessControlExpanded === -3 || parent.accessControlExpanded === 3,
                      }>
              Revoke  all  types  of  access
              <div class="line"></div>
              <div class="dot"></div>
            </li>

            <li class={
                      shown: parent.accessControlExpanded === 4,
                      visible: parent.accessControlExpanded === -4 || parent.accessControlExpanded === 4,
                      }>
              Remove the content permanently, on all devices
              <div class="line"></div>
              <div class="dot"></div>
            </li>
          </ul>
        </div>

        <div class="illustration">
          <div class={
                    'illustration-image': true,
                    'hidden': parent.accessControlExpanded > 0,
                    }>
            <div class="illustration-background"></div>
          </div>
        </div>
      </container>
    </div>
  </div>

  <div class="section-action-items">
    <container class="container">
      <div class="items">
        <a class="action-item" href="#how-it-works">Learn more</a><br />
        <a class="action-item" href="{parent.appStoreURL}">Download on the App Store</a>
      </div>
    </container>
  </div>

  <press></press>

  <footer app={opts.app} if={!opts.app.isSimple}></footer>

  <script>
   import * as scrollmagic from 'scrollmagic';

   import style from './landing.scss';

   import '../footer/footer.tag';
   import '../app-store-button/app-store-button.tag';
   import '../press/press.tag';
   import '../container/container.tag';

   const tag = this;
   tag.style = style;
   tag.appStoreURL = process.env.APP_STORE_URL;
   tag.accessControlExpanded = -1;

   const window = tag.opts.app.window;

   tag.on('mount', () => {
     const sectionAccessControls = tag.refs.sectionAccessControls;
     const sectionSecurity = tag.refs.sectionSecurity;
     const sectionActionItems = tag.refs.sectionActionItems;
     const scrollWrapper = tag.refs.sectionAccessControlsScrollWrapper;
     let controller = shouldAnimate() ? setupController() : null;

     window.addEventListener('resize', () => {
       const should = shouldAnimate();
       if (controller != null && !should) {
         controller.destroy(true);
         controller = null;
       }

       else if (controller == null && should) {
         controller = setupController();
       }
     });

     function shouldAnimate() {
       return window.matchMedia(`(max-width: ${process.env.MOBILE_BREAKPOINT}px)`).matches;
     }

     function setupController() {
       const controller = new scrollmagic.Controller();

       const scene = new scrollmagic.Scene({
         triggerElement: sectionAccessControls,
         triggerHook: 0,
         duration: 5000,
       });

       scene.setPin(sectionAccessControls);
       controller.addScene(scene);

       scene.on('progress', e => {
         const progress = e.progress;

         if (progress < 0.125) {
           tag.update({ accessControlExpanded: -1 });
         }
         else if (progress < 0.25) {
           tag.update({ accessControlExpanded: 1 });
         }
         else if (progress < 0.375) {
           tag.update({ accessControlExpanded: -2 });
         }
         else if (progress < 0.5) {
           tag.update({ accessControlExpanded: 2 });
         }
         else if (progress < 0.625) {
           tag.update({ accessControlExpanded: -3 });
         }
         else if (progress < 0.75) {
           tag.update({ accessControlExpanded: 3 });
         }
         else if (progress < 0.875) {
           tag.update({ accessControlExpanded: -4 });
         }
         else {
           tag.update({ accessControlExpanded: 4 });
         }
       });

       return controller;
     }
   });
  </script>
</landing>
