<app class={ 'is-simple': opts.app.isSimple }>
  <top-menu app={ opts.app } if={ !opts.app.isSimple } class="menu"></top-menu>
  <main id="page"></main>

  <script>
    import ready from '../../lib/ready.js';

    import '../top-menu/top-menu.tag';
    import '../landing/landing.tag';
    import '../how-it-works/how-it-works/how-it-works.tag';
    import '../open-source/open-source.tag';

    import './app.scss';

    const tag = this;

    // Simple mode, only render the content:
    if (opts.app.isSimple) {
      tag.on('mount', function() {
        opts.app.router.on('update', function(route) {
          riot.mount(page, route[0], { app: opts.app });
        });
      });
    } else {

      opts.app.config.IS_NODE &&
      opts.app.config.PRE_RENDER_TAG &&
      tag.on('mount', function() {
        const page = tag.root.childNodes.item(2);
        riot.mount(page, opts.app.config.PRE_RENDER_TAG, { app: opts.app });
      });

      !opts.app.config.IS_NODE &&
      tag.on('mount', function() {
        opts.app.router.on('update', function(route) {
          riot.mount(page, route[0], { app: opts.app });
          tag.update();
        });
      });

    }
  </script>
</app>
