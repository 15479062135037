<footer class={this.className}>
  <nav class="nav">
    <ul>
      <li><a href="/terms-of-use.html">Terms of use</a></li>
      <li><a href="/privacy-policy.html">Privacy policy</a></li>
      <li><a href="#open-source">Open source</a></li>
      <li><a href="/blog/">Blog</a></li>
      <li><a href="https://docs.google.com/document/d/1g3TQsyCVHT8Z5vzqXiOJ0mZxccjG0198CnRtmIc7t78">Whitepaper</a></li>
    </ul>
  </nav>

  <div class="copyright">
    <small>Copyright &copy; 2015-{copyrightEndYear} rumuki limited</small>
  </div>

  <script>
    import style from './footer.scss';

    this.style = style;
    this.className = {
      'no-background': opts.simple,
      [style.footer]: true,
    }

    this.copyrightEndYear = process.env.CURRENT_YEAR;
  </script>
</footer>
