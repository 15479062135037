<press class="{style.press} {opts.class}">
  <div class="container">
    <h5>In the press</h5>

    <div class="desktop">
      <img class="mentions" src={imageMentions} />
    </div>

    <div class="mobile">
      <img class="mentions" src={imageMentionsMobile} />
    </div>
  </div>

  <script>
   import style from './press.scss';
   import imageMentions from './mentions@2x.png'
   import imageMentionsMobile from './mentions-mobile@2x.png';

   this.style = style;

   this.imageMentions = imageMentions;
   this.imageMentionsMobile = imageMentionsMobile;

  </script>
</press>
