<how-it-works class="{style.howItWorks} {opts.class}">

  <container app={opts.app} class="container">

    <div class="heading">
      <h2>Privacy and security from the ground up</h2>
    </div>

    <grid-list>
      <ul>

        <li>
          <h3>We can't see your content.</h3>
          <p>
            Your content is encrypted in a way whereby only you or the recipient can decipher it.
          </p>
        </li>

        <li>
          <h3>We don't track what you're doing.</h3>
          <p>
            Many apps collect information on every action you take in order to fuel
            their analytics and marketing campaigns. We don't track anything.
          </p>
        </li>

        <li>
          <h3>We don't need to know who you are.</h3>
          <p>
            Use Rumuki anonymously. You don't need to provide an email address, social media account or any personal information.
          </p>
        </li>

        <li>
          <h3>We don't keep unnecessary information.</h3>
          <p>
            Things that we don't need to know like your display name or video names are never sent to us in plaintext.
          </p>
        </li>

      </ul>
    </grid-list>

    <div class="heading">
      <h2>Questions and answers</h2>
    </div>

    <grid-list>
      <faq></faq>
    </grid-list>

  </container>

  <footer app={ opts.app } simple={ true }></footer>

  <script>
    import { Observable, fromEvent, combineLatest } from 'rxjs';
    import { distinctUntilChanged, map, filter, startWith, shareReplay, take } from 'rxjs/operators';

    import '../../grid-list/grid-list.tag';
    import '../faq/faq.tag';
    import '../../container/container.tag';
    import '../../footer/footer.tag';

    import style from './how-it-works.scss';

    this.style = style;
  </script>
</how-it-works>
