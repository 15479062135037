'use strict';

import * as _ from 'underscore';
import * as rx from 'rxjs';

import riot from 'riot';
import 'riot-hot-reload';

import Router from './core/router.js';
import * as config from './core/config.js';
import './lib/requestAnimationFrame.js';

import './tags/app/app.tag';

// import './reset.scss';
import './index.scss';

// Simple mode is when we want to display a page inside a webview
// in an app, and therefore don't want the header and footer:
const isSimple = window.location.href.indexOf('?simple') !== -1;

const router = new Router();

// NOTE that the following context only gets applied to the **root** tag,
// i.e child tags need to have their `opts` explicitly passed down.
riot.mount('app', {
  app: {
    window: window,
    document: document,
    config: config,
    router: router,
    isSimple: isSimple,
  }
});

router.start();

// Support for google analytics
router.on('update', _.after(1, () => {
  if (window && _.isFunction(window.ga)) {
    ga('send', 'pageview',
       window.location.pathname +
       window.location.search +
       window.location.hash);
  }
}));
