<top-menu class="{style.topMenu} {opts.class}">
  <container class="container">

    <nav>
      <ul>
        <li class="logo"><text-logo></text-logo></li>

        <li>
          <ui-button
            class="menu-item"
            route={ [''] }
            type="simple">Home</ui-button>
        </li>

        <li>
          <ui-button
            class="menu-item"
            route={ ['how-it-works'] }
            type="simple">FAQ</ui-button>
        </li>

        <li>
          <ui-button
            href="{appStoreURL}"
            type="primary">Get the app</ui-button>
        </li>
      </ul>
    </nav>

  </container>

  <script>
   import style from './top-menu.scss';

   import '../text-logo/text-logo.tag';
   import '../ui-button/ui-button.tag';
   import '../container/container.tag';

   this.style = style;
   this.appStoreURL = process.env.APP_STORE_URL;
  </script>
</top-menu>
