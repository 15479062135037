<app-store-button class="{style.appStoreButton} {opts.class}">
  <a href="{appStoreURL}" class="ios-download-on-app-store">
    Download on the app store
  </a>

  <script>
   import style from './app-store-button.scss';
   this.style = style;
   this.appStoreURL = process.env.APP_STORE_URL;
  </script>
</app-store-button>
