<github-card class="{style.githubCard} {opts.class}">
  <a class="forked"
     if={opts.forked}
     href={this.forkedURL()}>⑂ forked</a>

  <dl>
    <dd class="repo">
      <a href={this.repoURL()}>
        { opts.repo }
      </a>
    </dd>
    <dd class="description"><yield /></dd>
  </dl>

  <script>
   import style from './github-card.scss';

   this.style = style;

   this.forkedURL = function() {
     if (opts.forked) {
       return 'https://github.com/' + opts.forked;
     }
     return '';
   };
   this.repoURL = function() {
     return 'https://github.com/' + opts.repo;
   };
  </script>
</github-card>
