<open-source class="{style.openSource} {opts.class}">

  <container class="container">

    <div class="heading">
      <h3>Open source</h3>

      <p>Rumuki is built atop of an assortment of open source libraries. And with the aim of transparency, these are
      listed below.</p>

      <p><em>Huge thanks</em> to all of the awesome people around the world contributing to these projects and more!</p>
    </div>

    <grid-list>
      <ul>
        <li>
          <github-card repo="ReactiveX/RxSwift">
            Reactive Programming in Swift
          </github-card>
        </li>

        <li>
          <github-card repo="Alamofire/Alamofire">
            Elegant HTTP Networking in Swift
          </github-card>
        </li>

        <li>
          <github-card repo="radex/SwiftyUserDefaults">
            Modern Swift API for NSUserDefaults
          </github-card>
        </li>

        <li>
          <github-card repo="JonasGessner/JGProgressHUD">
            Modern and extensive Apple-style progress HUD for iOS
          </github-card>
        </li>

        <li>
          <github-card repo="SnapKit/SnapKit">
            A Swift Autolayout DSL for iOS & OS X
          </github-card>
        </li>

        <li>
          <github-card repo="soffes/SAMKeychain">
            Simple Objective-C wrapper for the keychain that works on Mac and iOS
          </github-card>
        </li>

        <li>
          <github-card repo="emaloney/CleanroomLogger">
            CleanroomLogger provides an extensible Swift-based logging API that is simple, lightweight and performant
          </github-card>
        </li>

        <li>
          <github-card repo="realm/realm-cocoa">
            Realm is a mobile database: a replacement for Core Data & SQLite
          </github-card>
        </li>

        <li>
          <github-card repo="thoughtbot/Argo">
            Functional JSON parsing library for Swift
          </github-card>
        </li>

        <li>
          <github-card repo="thoughtbot/Curry">
            Swift implementations for function currying
          </github-card>
        </li>

        <li>
          <github-card repo="jedisct1/swift-sodium">
            Safe and easy to use crypto for iOS
          </github-card>
        </li>

        <li>
          <github-card repo="Grouper/FlatUIKit" forked="nathankot/FlatUIKit">
            A collection of awesome flat UI components for iOS
          </github-card>
        </li>

        <li>
          <github-card repo="IdleHandsApps/IHKeyboardAvoiding" forked="nathankot/IHKeyboardAvoiding">
            IHKeyboardAvoiding is an elegant solution for keeping any UIView visible when the keyboard is being shown
          </github-card>
        </li>

        <li>
          <github-card repo="IdleHandsApps/IHKeyboardDismissing" forked="nathankot/IHKeyboardDismissing">
            A custom view that, when tapped, will dismiss the keyboard
          </github-card>
        </li>

        <li>
          <github-card repo="neoneye/SpecificationPattern" forked="nathankot/SpecificationPattern">
            Specification pattern implemented in swift
          </github-card>
        </li>

        <li>
          <github-card repo="Imgur/Hermes" forked="nathankot/Hermes">
            Hermes is a simple and robust in-app notification system for iOS written in Swift
          </github-card>
        </li>

        <li>
          <github-card repo="aschuch/QRCode">
            A QRCode generator written in Swift.
          </github-card>
        </li>
      </ul>
    </grid-list>

    <div class="heading">
      <h3>Rumuki source code</h3>

      <p>A subset of Rumuki's source code is available to view. We plan to make available more components down the
      road.</p>
    </div>

    <grid-list>
      <ul>
        <li>
          <github-card repo="rumuki/rumuki-server">
            The key server that powers Rumuki
          </github-card>
        </li>
      </ul>
    </grid-list>

  </container>

  <footer app={ opts.app } simple={ true }></footer>

  <script>
   import style from './open-source.scss';
   import '../github-card/github-card.tag';
   import '../container/container.tag';

   this.style = style;
  </script>
</open-source>
