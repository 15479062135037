<ui-button class={className}>
  <a href={ href }
     class="text"
     onclick={ click }>
    <yield />
  </a>

  <script>
   import style from './ui-button.scss';

   this.className = `${style.uiButton} ${this.opts.class}`;

   const _ = require('underscore');
   const tag = this;

   const update = () => {
     tag.className = {
       primary: opts.type === "primary",
       disabled: opts.disabled != null,
       [style.uiButton]: true,
       [this.opts.class]: true,
     };

     if (opts.href) {
       tag.href = opts.href;
     }

     else if (opts.route) {
       tag.href = '#' + (_.isString(opts.route)
                       ? opts.route
                       : opts.route.join('/'));
     }

     else if (opts.action) {
       tag.href = '#';
       tag.click = (e) => {
         e.preventDefault && e.preventDefault();
         _.isFunction(opts.action) && opts.action();
       };
     }

   };

   tag.on('mount', update);
   tag.on('update', update);
  </script>
</ui-button>
